import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Alert as MuiAlert,
  Box,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Button as MuiButton,
  TextField as MuiTextField,
  Divider,
  Typography,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import axios from "axios";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import SelectWrapper from "./SmallSelectBox";
import { Delete as DeleteIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DataGrid, GridToolbar, GridFooter } from "@mui/x-data-grid";
import moment from "moment";
import ViewCustomerInvoiceDialog from "../components/ViewCustomerInvoiceDialog";
import apiUtils from "../utils/apiUtils";
import SelectWrapperDB from "../components/SelectBoxDataGridCell";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

export default function ViewClaimDialog(props) {
  const supplierData = apiUtils.useApiData("/api/v1/suppliers");
  const currencyData = apiUtils.useApiData("/api/v1/currencies");
  const [productData, setProductData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      axios.get("/api/v1/productTypes").then((response) => setProductData(response.data));
    }
    fetchData();
  }, []);
  const invoiceLabelData = apiUtils.useApiData("/api/v1/invoiceLabels");
  const invoiceStateData = apiUtils.useApiData("/api/v1/invoiceStates");
  const countryData = apiUtils.useApiData("/api/v1/countries");
  const claimStateData = apiUtils.useApiData("/api/v1/claimStates");
  const bankAccounts = apiUtils.useApiData("/api/v1/bankAccounts");

  const { viewClaimDialog, setViewClaimDialog, refreshTable } = props;
  // console.table(viewClaimDialog);
  var customerId = viewClaimDialog?.custInfo[0]?.customerId;
  var customerNumber = viewClaimDialog?.custInfo[0]?.customerNumber;
  const [hasPreFinancedInvoices, setHasPreFinancedInvoices] = useState(false);
  const [payableToDelta, setPayableToDelta] = useState(false); // You can set this to a predefined value if needed
  const [invoiceGridData, setInvoiceGridData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updatingClaim, setUpdatingClaim] = useState(false);
  const [pageSize, setPageSize] = React.useState(15);
  const [dataVersion, setDataVersion] = useState(0);

  const [selectedPaymentTypeId, setSelectedPaymentTypeId] = useState("");
  const [selectedClaimStateId, setSelectedClaimStateId] = useState("");
  const [selectedLiters, setSelectedLiters] = useState("");
  const [selectedAdvancedRefundAmountEUR, setSelectedAdvancedRefundAmountEUR] = useState("");
  const [selectedClaimDate, setSelectedClaimDate] = useState("");
  const [selectedNotificationDate, setSelectedNotificationDate] = useState("");
  const [selectedReimbursementDate, setSelectedReimbursementDate] = useState("");
  const [selectedPaymentDate, setSelectedPaymentDate] = useState("");
  const [selectedBankAccount, setSelectedBankAccount] = useState(() => {
    return viewClaimDialog?.params?.payableBankAccountId;
  });
  // const [selectedBankAccountDelta, setSelectedBankAccountDelta] = useState("");
  const [selectedClaimPreview, setSelectedClaimPreview] = useState([]);
  const [selectedTaxOfficeRefNo, setSelectedTaxOfficeRefNo] = useState("");

  const [invoiceCount, setInvoiceCount] = useState(0);
  const [totalGrossAmount, setTotalGrossAmount] = useState(0);
  const [totalVATAmount, setTotalVATAmount] = useState(0);
  const [missingInvoiceCopies, setMissingInvoiceCopies] = useState(0);
  const [gridTotalGrossAmount, setGridTotalGrossAmount] = useState(0);
  const [gridTotalVATAmount, setGridTotalVATAmount] = useState(0);
  const [bankAccountsFiltered, setBankAccountsFiltered] = useState([]);
  // const [bankAccountsDelta, setBankAccountsDelta] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [open, setOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  };

  const popupStyle = {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "5px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
    textAlign: "center",
  };

  const [alertInfo, setAlertInfo] = useState({ message: "", type: "" });

  const gridRef = React.useRef();

  const calculateTotals = useCallback(() => {
    if (invoiceGridData.length === 0) {
      return;
    }
    let invoiceCount = 0;
    let totalGrossAmount = 0;
    let totalVATAmount = 0;
    let missingInvoiceCopies = 0;
    let gridTotalGrossAmount = 0;
    let gridTotalVATAmount = 0;
    invoiceGridData.forEach((row) => {
      gridTotalGrossAmount += row.invoiceGrossAmount;
      gridTotalVATAmount += row.invoiceVATAmountLocalCurrency;
      if (row.invoiceStateId === 3) {
        invoiceCount++;
        totalGrossAmount += row.invoiceGrossAmount;
        totalVATAmount += row.invoiceVATAmountLocalCurrency;
      }
    });
    setInvoiceCount(invoiceCount);
    setTotalGrossAmount(totalGrossAmount);
    setTotalVATAmount(totalVATAmount);
    setMissingInvoiceCopies(missingInvoiceCopies);
    setGridTotalGrossAmount(gridTotalGrossAmount);
    setGridTotalVATAmount(gridTotalVATAmount);
  }, [invoiceGridData]);

  const selectedClaimId = useRef();

  useEffect(() => {
    selectedClaimId.current = viewClaimDialog?.params?.claimId;
    getPreviewData();
  }, [viewClaimDialog, dataVersion]);

  useEffect(() => {
    calculateTotals();
  }, [calculateTotals]);

  const [viewCustomerInvoiceDialog, setViewCustomerInvoiceDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    custInfo: [],
    productInfo: [],
  });

  // console.table(selectedClaimPreview.claimCode ? selectedClaimPreview.claimCode : "0");

  const [claimCode, setClaimCode] = useState(selectedClaimPreview.claimCode ? selectedClaimPreview.claimCode : "0");
  const claimCodeSuffix = claimCode.slice(-2);
  useEffect(() => {
    if (selectedClaimPreview.claimCode) {
      setClaimCode(selectedClaimPreview.claimCode);
    }
  }, [selectedClaimPreview.claimCode]); // Depend on claimCode specifically if it's available

  var country = selectedClaimPreview.country ? selectedClaimPreview.country : 0;
  var year = selectedClaimPreview.year ? selectedClaimPreview.year : 0;
  var startMonth = selectedClaimPreview.startMonth ? selectedClaimPreview.startMonth : 0;
  var endMonth = selectedClaimPreview.endMonth ? selectedClaimPreview.endMonth : 0;
  var claimStateId = selectedClaimPreview.claimStateId ? selectedClaimPreview.claimStateId : 0;
  var initPayableBankAccountId = selectedClaimPreview.PayableBankAccountId ? selectedClaimPreview.PayableBankAccountId : 0;
  // var taxOfficeRefNo = selectedClaimPreview.taxOfficeRefNo ? selectedClaimPreview.taxOfficeRefNo : "";

  useEffect(() => {
    setSelectedPaymentTypeId(selectedClaimPreview.paymentTypeId);
    setSelectedClaimStateId(selectedClaimPreview.claimStateId);
    setSelectedBankAccount(selectedClaimPreview.payableBankAccountId);
    setSelectedLiters(selectedClaimPreview.liters);
    setSelectedAdvancedRefundAmountEUR(selectedClaimPreview.advancedRefundAmountEUR);
    setSelectedClaimDate(moment(selectedClaimPreview.claimDate).format("YYYY-MM-DD"));
    setSelectedNotificationDate(moment(selectedClaimPreview.notificationDate).format("YYYY-MM-DD"));
    setSelectedReimbursementDate(moment(selectedClaimPreview.reimbursementDate).format("YYYY-MM-DD"));
    setSelectedPaymentDate(moment(selectedClaimPreview.payDate).format("YYYY-MM-DD"));
    setBankAccountsFiltered(bankAccounts.filter((bankAccount) => bankAccount.entityId === parseInt(customerId)));
    setSelectedTaxOfficeRefNo(selectedClaimPreview.taxOfficeRefNo);
    // setBankAccountsDelta(
    //   bankAccounts.filter((bankAccount) => bankAccount.entityId === 999999)
    // );
  }, [selectedClaimPreview, bankAccounts, customerId]);

  // console.log("Test", selectedClaimPreview);

  const handleInvoiceLabelUpdate = (params) => {
    const rowIndex = invoiceGridData.findIndex((row) => row.customerInvoiceId === params.customerInvoiceId);
    invoiceGridData[rowIndex].invoiceLabelId = params.invoiceLabelId;
  };

  const handleInvoiceStateUpdate = (params) => {
    const rowIndex = invoiceGridData.findIndex((row) => row.customerInvoiceId === params.customerInvoiceId);
    invoiceGridData[rowIndex].invoiceStateId = params.invoiceStateId;

    // Invoice to next claim
    // console.table(invoiceStateData);
    let invoiceStateId = invoiceStateData.find((x) => x.invoiceStateShortCode === "TNC").invoiceStateId;
    if (params.invoiceStateId === invoiceStateId) {
    }
    // console.log(invoiceStateId);
    calculateTotals();
  };

  const columns = [
    {
      field: "invoiceNumber",
      headerName: "Invoice Number",
      flex: 0.5,
      hide: false,
    },
    {
      field: "invoiceDate",
      headerName: "Invoice Date",
      flex: 0.4,
      valueFormatter: (params) => moment(params?.value).format("DD-MM-YYYY"),
    },
    {
      field: "supplierId",
      headerName: "Supplier",
      flex: 0.5,
      valueGetter: (params) => supplierData.find((supplier) => supplier.supplierId === params.row.supplierId)?.supplierName,
    },
    {
      field: "productTypeId",
      headerName: "Product",
      flex: 0.4,
      valueGetter: (params) =>
        params.row.claimProductTypeId === -1
          ? "10 Custom"
          : productData.find((product) => product.productTypeId === params.row.claimProductTypeId)?.productShortDescription,
    },
    {
      field: "countryCode",
      headerName: "Country",
      flex: 0.3,
      valueGetter: (params) => countryData.find((country) => country.countryId === params.row.countryCode).countryCode,
    },
    {
      field: "currencyId",
      headerName: "Currency",
      flex: 0.3,
      valueGetter: (params) => currencyData.find((cur) => cur.currencyId === params.row.currencyId).isoCode,
    },
    {
      field: "invoiceVATAmountLocalCurrency",
      headerName: "Local VAT Amount",
      flex: 0.5,
      renderCell: (params) => (
        <div style={{ textAlign: "right", paddingRight: "5px" }}>
          {params.value.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      ),
      align: "right",
    },
    {
      field: "fileId",
      headerName: "Invoice PDF available",
      flex: 0.5,
      renderCell: (params) =>
        params.value > 0 ? (
          <CheckCircleOutlineIcon style={{ color: "green", margin: "0 auto" }} />
        ) : (
          <CheckCircleOutlineIcon style={{ color: "red", margin: "0 auto" }} />
        ),
    },
    {
      field: "InvoiceState",
      headerName: "Invoice State",
      flex: 0.6,
      renderCell: (params) => {
        const handleChange = (selectedKey) => {
          const rowData = params.row;
          const updatedRowData = {
            ...rowData,
            invoiceStateId: selectedKey,
          };
          handleInvoiceStateUpdate(updatedRowData);
        };

        // Determine the disabled state for the entire SelectWrapperDB based on claimStateId
        const selectDisabled = selectedClaimPreview.claimStateId === 16;

        // Process options to add a disabled property for specific options when claimStateId is 4
        let processedOptions = JSON.parse(JSON.stringify(invoiceStateData)).map((option) => {
          let disabledOption = selectedClaimPreview.claimStateId === 4 && [1, 2, 4].includes(option.invoiceStateId);
          return {
            ...option,
            key: option.invoiceStateId, // Transforming invoiceStateId to key for SelectWrapper compatibility
            value: option.invoiceStateShortCode, // Transforming invoiceStateShortCode to value for SelectWrapper compatibility
            disabled: disabledOption, // Disabling specific options based on claimStateId
          };
        });

        return (
          <SelectWrapperDB
            disabled={selectDisabled} // Disabling the entire SelectWrapperDB based on claimStateId
            name="invoiceState"
            rowIndex={params.api.getRowIndex(params.row.__rowNum__)}
            selectedKey={params.row.invoiceStateId}
            options={processedOptions}
            handleChange={handleChange}
          />
        );
      },
    },
    {
      field: "InvoiceLabel",
      headerName: "Invoice Label",
      flex: 0.6,
      renderCell: (params) => {
        const handleChange = (selectedKey) => {
          const rowData = params.row;
          const updatedRowData = {
            ...rowData,
            invoiceLabelId: selectedKey,
          };
          handleInvoiceLabelUpdate(updatedRowData);
        };

        return (
          <SelectWrapperDB
            name="invoiceLabel"
            rowIndex={params.api.getRowIndex(params.row.__rowNum__)}
            selectedKey={params.row.invoiceLabelId}
            options={JSON.parse(
              JSON.stringify(JSON.parse(JSON.stringify(invoiceLabelData).split('"invoiceLabelId":').join('"key":')))
                .split('"invoiceLabelShortCode":')
                .join('"value":')
            )}
            handleChange={handleChange}
          />
        );
      },
    },
    {
      field: "excludeFromClaim",
      headerName: "Exclude from Claim",
      flex: 0.5,
      hide: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.3,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              onClick={(e) => {
                setViewCustomerInvoiceDialog({
                  isOpen: true,
                  downloadFile: true,
                  title: "Manage Invoice",
                  params: params.row,
                  custInfo: [{ customerId: customerId, customerNumber: customerNumber }],
                  productInfo: [
                    {
                      productCode: params.row.claimProductCode,
                      productSubCode: params.row.claimProductSubCode,
                    },
                  ],
                  subTitle: "Are you sure you want to manage " + params.row.contactName + "?",
                  handleFile: () => {
                    handleFile(updatedData);
                  },
                });
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const FooterRow = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 10,
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ fontWeight: "bold", marginRight: 10 }}>Total Net Amount:</div>
          <div>
            {gridTotalGrossAmount.toLocaleString("nl-NL", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
          <div style={{ fontWeight: "bold", marginLeft: 20, marginRight: 10 }}>Total VAT Amount:</div>
          <div>
            {gridTotalVATAmount.toLocaleString("nl-NL", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        </div>
        <div>
          <GridFooter
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[15, 50, 100]}
          />
        </div>
      </div>
    );
  };

  var updatedData;

  const handleFile = (updatedData) => {};

  const getRowClassName = (params) => {
    const invoiceGrossAmount = params.row.invoiceGrossAmount;
    const invoiceStateId = params.row.invoiceStateId;

    if (invoiceStateId === 4) {
      return "highlight-row-removed";
    }

    if (invoiceStateId === 3) {
      return "highlight-row-ready-for-claim";
    }

    if (invoiceStateId === 2) {
      return "highlight-row-not-claimable";
    }

    if (invoiceGrossAmount > 3000) {
      return "highlight-row";
    }

    return "";
  };

  const handleBankAccountValue = (value, bankAccountId) => {
    setSelectedBankAccount(value);
  };
  const handleClaimStateValue = (value, name) => {
    setSelectedClaimStateId(value);
    if (value === 4) {
      setSelectedClaimDate(new Date().toISOString().slice(0, 10));
    }
    if (value === 16) {
      if (claimCodeSuffix === "00") {
        setSelectedNotificationDate(new Date().toISOString().slice(0, 10));
      } else {
        setSelectedReimbursementDate(new Date().toISOString().slice(0, 10));
      }
    }
  };

  const handleSelectedClaimDateValue = (event) => {
    // var test = setSelectedClaimDate(event.target.value);
    // console.log(test);
  };
  const handleSelectedNotificationDateValue = (event) => {
    setSelectedNotificationDate(event.target.value);
  };
  const handleSelectedReimbursementDateValue = (event) => {
    setSelectedReimbursementDate(event.target.value);
  };
  const handleSelectedPaymentDateValue = (event) => {
    setSelectedPaymentDate(event.target.value);
  };
  const handleTaxOfficeRefNo = (event) => {
    setSelectedTaxOfficeRefNo(event.target.value);
  };

  const getPreviewData = async () => {
    if (!selectedClaimId.current) {
      return;
    }
    setLoading(true);
    await axios.post("/api/v1/claims/claimView/" + selectedClaimId.current).then((response) => {
      setSelectedClaimPreview(response.data);
      // Filter the invoices list based on claimCode and claimStateId
      const filteredInvoices = response.data.customerInvoices.filter(
        (invoice) =>
          ((invoice.invoiceStateId === 1 || invoice.invoiceStateId === 3 || invoice.invoiceStateId === 9) &&
            invoice.claimId === response.data.claimId) ||
          ((invoice.invoiceStateId === 2 || invoice.invoiceStateId === 3 || invoice.invoiceStateId === 4) &&
            (invoice.claimId === null || invoice.claimId === 0) &&
            response.data.claimStateId === 1)
      );
      // let customerInvoices = response.data.customerInvoices;
      // console.table(response.data.customerInvoices);
      setHasPreFinancedInvoices(filteredInvoices.some((invoice) => invoice.preFinanceId > 0));
      setPayableToDelta(response.data.claimCode.endsWith("1"));
      setSelectedBankAccount(response.data.payableBankAccountId);
      setInvoiceGridData(filteredInvoices);
      setLoading(false);
    }, 2000);
  };

  const [entityData, setEntityData] = useState([]);

  useEffect(() => {
    if (!customerId) {
      return;
    }
    axios.get("/api/v1/entities/" + customerId).then((response) => setEntityData(response.data));
  }, [customerId]);

  const handleUpdateClaim = async () => {
    setUpdatingClaim(true);

    // Find exchange rate for the specified country
    const exchangeRate = countryData.find((country) => country.countryId === viewClaimDialog.params.countryId)?.exchangeRate || 1;

    // Calculate claimVATAmountEUR
    const claimVATAmountEUR = totalVATAmount * exchangeRate;

    // Update claim in DB
    let claimArray = {
      claimCode: claimCode,
      paymentTypeId: selectedPaymentTypeId,
      claimStateId: selectedClaimStateId,
      liters: selectedLiters,
      advancedRefundAmountEUR: selectedAdvancedRefundAmountEUR,
      claimDate: selectedClaimDate,
      notificationDate: selectedNotificationDate,
      reimbursementDate: selectedReimbursementDate,
      payDate: selectedPaymentDate,
      PayableBankAccountId: selectedBankAccount,
      // paymentToDelta: payableToDelta ? 1 : 0,
      taxOfficeRefNo: selectedTaxOfficeRefNo,
      claimGrossAmount: totalGrossAmount,
      claimVATAmountLocCur: totalVATAmount,
      claimVATAmountEUR: claimVATAmountEUR,
    };

    invoiceGridData.forEach((invoice) => {
      if (invoice.invoiceStateId === 2 || invoice.invoiceStateId === 4) {
        invoice.claimId = 0;
      } else {
        invoice.claimId = selectedClaimPreview.claimId;
      }
    });

    axios
      .put("/api/v1/claims/claimViewUpdate/" + selectedClaimPreview.claimId, claimArray, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return axios.put("/api/v1/customerInvoices/updateInvoiceEditFormBulk", invoiceGridData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      })
      .then((response) => {
        // console.table(response);
      })
      .then((response) => {
        // console.log("I'm here!");
        setDataVersion(dataVersion + 1);
      })
      .then((response) => {
        axios
          .post("/api/v1/claims/updateInvoiceAmounts/" + selectedClaimPreview.claimId)
          .catch(function (error) {
            console.log("Update error!: " + error);
          })
          .finally(() => {});
      })
      .catch(function (error) {
        // console.log(error);
        alert("Something went wrong while updating the invoices.");
      });

    getPreviewData();

    await delay(1000);
    setUpdatingClaim(false);

    // Call refreshTable to refresh the DataGrid in customerClaims
    refreshTable();
  };

  const handleDeleteClaim = async () => {
    let selectedClaimId = selectedClaimPreview.claimId;
    axios
      .delete("/api/v1/claims/" + selectedClaimId)
      .then((response) => {
        // console.table(response.data);
        setDataVersion(dataVersion + 1);
        // Remove ClaioamId from respective invoices
        axios.put("/api/v1/customerInvoices/updateInvoiceRemoveClaimId/" + selectedClaimId).then((response) => {
          // console.log(response);
        });

        // On success, set the alert message and type
        setAlertInfo({
          message: "The claim was deleted successfully!",
          type: "success",
        });

        // Use setTimeout to remove the Alert after 5 seconds
        setTimeout(() => setAlertInfo({ message: "", type: "" }), 5000);
      })
      .catch(function (error) {
        // On error, set the alert message and type
        setAlertInfo({
          message: "Something went wrong while deleting the claim.",
          type: "error",
        });

        // Use setTimeout to remove the Alert after 5 seconds
        setTimeout(() => setAlertInfo({ message: "", type: "" }), 5000);
      });
    await delay(5000);
    setViewClaimDialog({
      ...viewClaimDialog,
      isOpen: false,
    });
    props.refreshTable();
    // getPreviewData();
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleXMLDownload = () => {
    let claimId = selectedClaimPreview.claimId;

    var getClaimUrl;
    if (entityData.countryId === 1) {
      getClaimUrl = "/api/v1/claims/generateClaimZIP_NL/" + claimId;
    } else if (entityData.countryId === 17) {
      getClaimUrl = "/api/v1/claims/generateClaimZIP_PL/" + claimId;
    }
    // console.log(getClaimUrl);
    if (!getClaimUrl) {
      return;
    }
    // console.log(getClaimUrl);
    axios({
      url: getClaimUrl,
      method: "GET",
      responseType: "blob", // Important to set the response type to blob
    })
      .then((response) => {
        // Get the filename from the Content-Disposition header
        const contentDisposition = response.headers["content-disposition"];
        let filename = "file.zip"; // Default filename
        if (contentDisposition) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          let matches = filenameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "");
          }
        }

        // Create a blob from the PDF stream
        const file = new Blob(
          [response.data],
          { type: "application/xml" } // The MIME type of the data
        );
        // Create a link element
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = filename; // The name of the downloaded file
        link.click();
      })
      .catch(function (error) {
        alert("Something went wrong while generating the XML file.");
      });
  };

  const handleXMLSendClaimToTaxAuthorities = () => {
    setShowPopup(true);

    let claimId = selectedClaimPreview.claimId;

    axios({
      url: "/api/v1/claims/sendClaimToTaxAuthority_NL/" + claimId,
      method: "GET",
      responseType: "text", // Important to set the response type to blob
    })
      .then((response) => {
        if (response.status === 200) {
          setResponseMessage("Upload successful"); // Set the response message
        } else {
          setResponseMessage("Something went wrong.");
        }
      })
      .catch(function (error) {
        setResponseMessage("Something went wrong while generating the XML file.");
      });
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleClickOpenConfirmation = () => {
    setOpenConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  const handleChange = () => {
    setOpen(true);
  };

  const handleClose = (answer) => {
    if (answer) {
      // Update the last digit of claimCode
      if (hasPreFinancedInvoices) {
        setClaimCode(claimCode.slice(0, -2) + "10");
      } else {
        setClaimCode(claimCode.slice(0, -2) + "00");
      }
      setClaimCode((prevCode) => `${prevCode.slice(0, -1)}${payableToDelta ? "0" : "1"}`);
    }
    setPayableToDelta(!payableToDelta);
    setOpen(false);
  };

  const StatusMessage = () => {
    const { uploadStatus, claimSequenceNumber } = viewClaimDialog.params;

    return (
      <Box display="flex" justifyContent="flex-end" alignItems="center" mt={2} mb={2}>
        {claimSequenceNumber > 0 && (
          <>
            {uploadStatus === "acknowledgement" && (
              <Box display="flex" alignItems="center" mr={2}>
                <Box width={15} height={15} bgcolor="#b2f2bb" mr={0.5} />
                <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
                  Claim upload status OK
                </Typography>
              </Box>
            )}

            {uploadStatus === "" && (
              <Box display="flex" alignItems="center" mr={2}>
                <Box width={15} height={15} bgcolor="#a5d8ff" mr={0.5} />
                <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
                  Claim upload in progress
                </Typography>
              </Box>
            )}

            {uploadStatus !== "acknowledgement" && uploadStatus !== "" && (
              <Box display="flex" alignItems="center">
                <Box width={15} height={15} bgcolor="#ffc9c9" mr={0.5} />
                <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
                  Error while uploading claim
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
    );
  };

  return (
    <div>
      <Dialog open={open} onClose={() => handleClose(false)}>
        <DialogTitle>{"Change Claim Code"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Do you want to change the Claim Code to reflect the change?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary">
            No
          </Button>
          <Button onClick={() => handleClose(true)} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirmation}
        onClose={handleCloseConfirmation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to submit the claim XML?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmation} color="primary">
            No
          </Button>
          <Button
            onClick={() => {
              handleXMLSendClaimToTaxAuthorities();
              handleCloseConfirmation();
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {showPopup && (
        <div style={overlayStyle}>
          <div style={popupStyle}>
            {responseMessage ? (
              <>
                <p>{responseMessage}</p>
                <p>
                  <button
                    onClick={handleClosePopup}
                    style={{
                      border: "none",
                      background: "none",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Close
                  </button>
                </p>
              </>
            ) : (
              <p>Please wait, upload in progress...</p>
            )}
          </div>
        </div>
      )}
      <Dialog
        open={viewClaimDialog.isOpen}
        //   onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle id="alert-dialog-title">{viewClaimDialog.title}</DialogTitle>
        <Divider />
        <DialogContent>
          <Card mb={6}>
            <CardContent>
              {alertInfo.message && (
                <Alert severity={alertInfo.type} my={3}>
                  {alertInfo.message}
                </Alert>
              )}
              {updatingClaim ? (
                <Grid container xs={12} sx={{ mb: 5 }} justifyContent="center" alignItems="center" height={890}>
                  <Typography
                    sx={{
                      fontSize: "21px",
                      mt: 2,
                    }}
                    align="center"
                  >
                    <CircularProgress size={30} sx={{ mr: 2 }} />
                    Updating, please wait!
                  </Typography>
                </Grid>
              ) : (
                <div>
                  <Grid container xs={12} sx={{ mb: 5 }}>
                    <Grid container xs={6} spacing={1} sx={{ pr: 2 }}>
                      <Grid item xs={5}>
                        <Typography>Claim Code:</Typography>
                      </Grid>
                      <Grid item xs={5}>
                        {claimCode}
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>Reference number:</Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          type="string"
                          value={selectedTaxOfficeRefNo}
                          onChange={handleTaxOfficeRefNo}
                          sx={{
                            "& .MuiOutlinedInput-input": {
                              padding: 1.0,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>Year:</Typography>
                      </Grid>
                      <Grid item xs={5}>
                        {year}
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>Period:</Typography>
                      </Grid>
                      <Grid item xs={5}>
                        {startMonth.toString().padStart(2, "0")} / {endMonth.toString().padStart(2, "0")}
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>Country:</Typography>
                      </Grid>
                      <Grid item xs={5}>
                        {countryData.find((country1) => country1.countryId === country)?.countryCode}
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>Claim State:</Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <SelectWrapper
                          disabled={selectedClaimPreview.claimStateId === 4 ? true : false}
                          name="claimStateId"
                          label="Claim State"
                          options={JSON.parse(
                            JSON.stringify(JSON.parse(JSON.stringify(claimStateData).split('"claimStateId":').join('"key":')))
                              .split('"description":')
                              .join('"value":')
                          )}
                          handleChange={handleClaimStateValue}
                          value={selectedClaimStateId ? selectedClaimStateId : claimStateId}
                          selectedClaimPreviewClaimStateId={selectedClaimPreview.claimStateId}
                          enableSpecialBehavior={true}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>Claim Date:</Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          disabled={selectedClaimPreview.claimStateId === 4 ? true : false}
                          type="date"
                          value={selectedClaimDate}
                          onChange={handleSelectedClaimDateValue}
                          sx={{
                            "& .MuiOutlinedInput-input": {
                              padding: 1.0,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>Notification Date:</Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          type="date"
                          sx={{
                            "& .MuiOutlinedInput-input": {
                              padding: 1.0,
                            },
                          }}
                          onChange={handleSelectedNotificationDateValue}
                          value={selectedNotificationDate}
                        ></TextField>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>Reimbursement Date:</Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          type="date"
                          disabled={claimCodeSuffix === "00"}
                          sx={{
                            "& .MuiOutlinedInput-input": {
                              padding: 1.0,
                            },
                          }}
                          onChange={handleSelectedReimbursementDateValue}
                          value={selectedReimbursementDate}
                        ></TextField>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>Pay Date:</Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          type="date"
                          disabled={claimCodeSuffix === "00"}
                          sx={{
                            "& .MuiOutlinedInput-input": {
                              padding: 1.0,
                            },
                          }}
                          onChange={handleSelectedPaymentDateValue}
                          value={selectedPaymentDate}
                        ></TextField>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>Payable Bank Account:</Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={hasPreFinancedInvoices && payableToDelta}
                              checked={payableToDelta}
                              onChange={handleChange}
                              name="payableToDelta"
                              color="primary"
                            />
                          }
                          label={
                            <div>
                              Payment to Delta
                              {hasPreFinancedInvoices && payableToDelta && (
                                <div style={{ fontSize: "small" }}> (has pre financed invoices)</div>
                              )}
                            </div>
                          }
                        />
                        {!payableToDelta && (
                          <SelectWrapper
                            name="bankAccountId"
                            label="Bank Account"
                            options={JSON.parse(
                              JSON.stringify(JSON.parse(JSON.stringify(bankAccountsFiltered).split('"bankAccountId":').join('"key":')))
                                .split('"iban":')
                                .join('"value":')
                            )}
                            handleChange={handleBankAccountValue}
                            // value={selectedBankAccount}
                            value={selectedBankAccount ? selectedBankAccount : initPayableBankAccountId}
                            // style={{
                            //   border: "1px solid #ccc",
                            //   borderRadius: "4px",
                            // }}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid item xs={12} sx={{ p: 5, pt: 3 }} backgroundColor="#376fd0">
                        <Grid container>
                          <Grid item xs={5}>
                            <Typography sx={{ fontSize: 22, color: "white" }}>Invoice Count:</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              sx={{
                                fontSize: 22,
                                color: "white",
                                textAlign: "right",
                              }}
                            >
                              {invoiceCount}
                            </Typography>
                          </Grid>
                          <Grid item xs={5} />
                        </Grid>
                        <Grid container>
                          <Grid item xs={5}>
                            <Typography sx={{ fontSize: 22, color: "white" }}>Total Net Amount:</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              sx={{
                                fontSize: 22,
                                color: "white",
                                textAlign: "right",
                              }}
                            >
                              {(totalGrossAmount &&
                                totalGrossAmount.toLocaleString("nl-NL", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })) ||
                                "0,00"}
                            </Typography>
                          </Grid>
                          <Grid item xs={5} />
                        </Grid>
                        <Grid container>
                          <Grid item xs={5}>
                            <Typography sx={{ fontSize: 22, color: "white" }}>Total VAT Amount:</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              sx={{
                                fontSize: 22,
                                color: "white",
                                textAlign: "right",
                              }}
                            >
                              {(totalVATAmount &&
                                totalVATAmount.toLocaleString("nl-NL", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })) ||
                                "0,00"}
                            </Typography>
                          </Grid>
                          <Grid item xs={5} />
                        </Grid>
                        <Grid container>
                          <Grid item xs={5}>
                            <Typography sx={{ fontSize: 22, color: "white" }}>Missing Invoice Copies:</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              sx={{
                                fontSize: 22,
                                color: "white",
                                textAlign: "right",
                              }}
                            >
                              {missingInvoiceCopies}
                            </Typography>
                          </Grid>
                          <Grid item xs={5} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                      <StatusMessage />
                    </Grid>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: 10,
                        backgroundColor: "#376fd0",
                        color: "white",
                      }}
                    >
                      <Typography style={{ marginLeft: "auto" }}>
                        <Button
                          disabled={selectedClaimPreview.claimStateId === 3 ? false : true}
                          varianrt="text"
                          onClick={handleXMLDownload}
                          sx={{ color: "white", textDecoration: "underline" }}
                        >
                          Download claim XML
                        </Button>
                        {entityData.countryId === 1 && (
                          <Button
                            disabled={selectedClaimPreview.claimStateId === 3 ? false : true}
                            // disabled={true}
                            varianrt="text"
                            onClick={handleClickOpenConfirmation}
                            sx={{ color: "white", textDecoration: "underline" }}
                          >
                            Submit claim XML
                          </Button>
                        )}
                      </Typography>
                    </div>
                    <ViewCustomerInvoiceDialog
                      viewCustomerInvoiceDialog={viewCustomerInvoiceDialog}
                      setViewCustomerInvoiceDialog={setViewCustomerInvoiceDialog}
                      countryArr={countryData}
                      supplierArr={supplierData}
                      currencyArr={currencyData}
                      productTypeArr={productData}
                      invoiceStateArr={invoiceStateData}
                      refreshGrid={getPreviewData}
                    />
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "500px",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    ) : (
                      <DataGrid
                        getRowId={(row) => row.customerInvoiceId}
                        disableSelectionOnClick
                        disableColumnSelector
                        components={{ Footer: FooterRow, Toolbar: GridToolbar }}
                        density="compact"
                        componentsProps={{
                          toolbar: {
                            csvOptions: { disableToolbarButton: false },
                            printOptions: { disableToolbarButton: false },
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 250 },
                          },
                        }}
                        pagination
                        paginationMode="client"
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[15, 50, 100]}
                        rows={invoiceGridData}
                        columns={columns}
                        ref={gridRef}
                        getRowClassName={getRowClassName}
                        sx={{
                          height: 500,
                          width: "100%",
                          borderRadius: 1,
                          backgroundColor: "background.paper",
                          boxShadow: 2,
                          "& .MuiDataGrid-cell:hover": {
                            color: "primary.main",
                          },
                          marginTop: 0,
                          ".highlight-row": {
                            bgcolor: "#ffeeba",
                          },
                          ".highlight-row-removed": {
                            bgcolor: "#fae7e7",
                          },
                          ".highlight-row-ready-for-claim": {
                            bgcolor: "#c8e6c9",
                          },
                          ".highlight-row-not-claimable": {
                            bgcolor: "#d7ccc8",
                          },
                        }}
                      />
                    )}
                    {/* <TableEditColumn showAddCommand showEditCommand /> */}
                  </Grid>
                </div>
              )}
              <Grid item container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Button type="submit" variant="contained" color="primary" onClick={handleUpdateClaim} disabled={updatingClaim}>
                    Update Claim
                    {updatingClaim && <CircularProgress size={20} style={{ marginLeft: 10, color: "white" }} />}
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setViewClaimDialog({
                        ...viewClaimDialog,
                        isOpen: false,
                      });
                      setInvoiceGridData([]);
                      setSelectedClaimPreview([]);
                    }}
                    sx={{ marginLeft: 2 }}
                  >
                    Close
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={handleDeleteClaim}
                    sx={{ marginLeft: 2 }}
                    disabled={selectedClaimPreview.claimStateId !== 1}
                  >
                    Delete
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
    </div>
  );
}
